import './scheduler.css';

const iframeStyles = {
  border: 'none',
    minHeight: '662px'
};

function Scheduler(props) {
    return (
      <iframe 
        src="https://app.hellobonsai.com/s/jacob-mcmillan/consultation?embed=true" 
        width="1200px" 
        height="100%" 
        style={iframeStyles}
        title="Bonsai Scheduler"
        className="portfolio__scheduler"
      ></iframe>
    );
  }

export default Scheduler;
import React from 'react';
// import { video } from './imports';
import './hero.css';

const Hero = () => {
  return (
    <div className='portfolio__hero section__padding' id='home'>
      <div className="portfolio__hero-bg_overlay"></div>
      <div className='portfolio__hero-content'>
        <h1 className='portfolio__hero-content_title'>JMC Digital Studio</h1>
        <h4 className='portfolio__hero-content_subtitle'>Website Design, Front End Development, App Development</h4>
        <div className='portfolio__hero-content_btns'>
          <button type='button' onClick={() => window.location.href="#ContactForm"}>Get Started</button>
        </div>
      </div>
      {/* <div className='portfolio__hero-video'>
        <video 
          src={video}
          type="video/mp4"
          loop
          playbackRate='0.5'
          muted
          autoPlay
          playsInline
        />
      </div> */}
    </div>
  )
}

export default Hero

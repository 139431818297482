import React from 'react'
import Scheduler from '../../components/scheduler/Scheduler';
import './contactform.css';

const ContactForm = () => {
  return (
    <div className="portfolio__contactform section__padding" id="ContactForm">
      <div className="portfolio__contactform-heading">
        <h1>Contact Me</h1>
        <p>Use the below calendar to schedule a quick 30 minute consultation. We will use the time to discuss your web needs and determine the best way for me to assist in your endeavours.</p>
      </div>
      <div className="portfolio__contactform-scheduler">
        <Scheduler />
      </div>
    </div>
  )
}

export default ContactForm

import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/images/logo.png';
import './navbar.css';

function throttle(func, wait) {
  let timeout;
  return function(...args) {
      if (!timeout) {
          timeout = setTimeout(() => {
              timeout = null;
              func.apply(this, args);
          }, wait);
      }
  };
}

const Menu = ({ hovered, setHovered }) => (
  <>
    <p onMouseEnter={() => setHovered('about')} onMouseLeave={() => setHovered(null)}>
      <a href='#about' className={hovered && hovered !== 'about' ? 'faded' : ''}>
        { '// About' }
      </a>
    </p>
    <p onMouseEnter={() => setHovered('certificates')} onMouseLeave={() => setHovered(null)}>
      <a href='#certificates' className={hovered && hovered !== 'certificates' ? 'faded' : ''}>
        { '// Certificates' }
      </a>
    </p>
    <p onMouseEnter={() => setHovered('projects')} onMouseLeave={() => setHovered(null)}>
      <a href='#projects' className={hovered && hovered !== 'projects' ? 'faded' : ''}>
        { '// Projects' }
      </a>
    </p>
    <p onMouseEnter={() => setHovered('contact')} onMouseLeave={() => setHovered(null)}>
      <a href='#ContactForm' className={hovered && hovered !== 'contact' ? 'faded' : ''}>
        { '// Contact' }
      </a>
    </p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const onScroll = throttle(() => {
        const isScrolled = window.scrollY > 450;
  
        setIsVisible(window.scrollY < 150 || isScrolled);
        // Only update state if it's different
        if (isScrolled !== scrolled) {
            setScrolled(isScrolled);
        }
    }, 100);  // Adjust this value as needed
  
    window.addEventListener("scroll", onScroll);
    
    return () => {
        window.removeEventListener("scroll", onScroll);
    };
  }, [scrolled]);

  return (
    <div className={`portfolio__navbar ${scrolled ? "scrolled" : ""} ${isVisible ? "visible" : ""}`}>
      <div className='portfolio__navbar-logo'>        
          <img src={logo} alt="logo" /> 
      </div>
      <div className='portfolio__navbar-links'>
        <div className='portfolio__navbar-links_container'>
          <Menu hovered={hovered} setHovered={setHovered} />
        </div>
      </div>
      <div className='portfolio__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='portfolio__navbar-menu_container scale-up-center'>
            <div className='portfolio__navbar-menu_container-links'>
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar

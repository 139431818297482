import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className='portfolio__about section__padding' id='about'>
      <div className="portfolio__about-heading">
        <h1>About Me</h1>
      </div>
      <div className="portfolio__about-container">
        <a href='#ContactForm' className='portfolio__about-container_link'>
          <div className='portfolio__about-container_card'>
            <div className="portfolio__about-container_card-image">
              {/* <img src={product1} alt='about' /> */}
            </div>
            <div className="portfolio__about-container_card-content">
              <h2>Front End Development</h2>
              <h3><li>UI/UX</li><li>HTML and CSS</li><li>JavaScript</li><li>React Framework</li></h3>
            </div>
          </div>
        </a>
        <a href='#ContactForm' className='portfolio__about-container_link'>
          <div className='portfolio__about-container_card'>
            <div className="portfolio__about-container_card-image">
              {/* <img src={product2} alt='about' /> */}
            </div>
            <div className="portfolio__about-container_card-content">
              <h2>App Development</h2>
              <h3><li>Cross Platform</li><li>React Native</li><li>Flutter</li><li>Hybrid apps</li></h3>
            </div>
          </div>
        </a>
        <a href='#ContactForm' className='portfolio__about-container_link'>
          <div className='portfolio__about-container_card'>
            <div className="portfolio__about-container_card-image">
              {/* <img src={product3} alt='about' /> */}
            </div>
            <div className="portfolio__about-container_card-content">
              <h2>Software Development</h2>
              <h3><li>Backend Scripting</li><li>DB Initialization</li><li>Python</li><li>C#</li></h3>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default About
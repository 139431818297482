import { Navbar, Footer, CursorFollower } from './components';
import { Hero, About, Projects, ContactForm } from './containers';

import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <About />
      { /* <Certificates /> */ }
      <Projects />
      <ContactForm />
      <Footer />
      <CursorFollower />
    </div>
  );
}

export default App;

import React from 'react';
import './footer.css';

const Footer = () => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // This makes the scroll smoothly to the top
    });
  };

  return (
    <div className="portfolio__footer">
      <button onClick={scrollToTop} className="portfolio__footer-btn">^</button>
      <p>© 2023 JMC Digital Studio. All rights reserved.</p>
    </div>
  )
}

export default Footer;

import React from 'react'
import { Project } from '../../components';
import { github, nextlevel, ai, gericht, hoobank } from './imports';

import './projects.css';

const Projects = () => {
  return (
    <div className="portfolio__projects section__padding" id='projects'>
      <div className="portfolio__projects-title">
        <h1>My Projects</h1>
      </div>
      <div className="portfolio__projects-container">
        <div className="portfolio__projects-container_github">
          <Project 
            imgUrl={github} 
            title='Github Repo' 
            desc='See all of the code for any public projects that I have completed.' 
            Link='https://github.com/jmcwebdesign' 
          />
        </div>
        <div className="portfolio__projects-container_completed">
          <Project 
            imgUrl={nextlevel} 
            title="NextLevel CFO" 
            desc="Fully functional site for contract accounting startup." 
            Link="https://nextlevelcfo-stage.netlify.app/" 
          />
          <Project 
            imgUrl={ai} 
            title="GPT3" 
            desc="React app showcasing a modern responsive template." 
            Link="https://gpt3-stage.netlify.app/" 
          />
          <Project 
            imgUrl={gericht} 
            title="Gericht Restaurant" 
            desc="Elegant and high end website with complex layout template." 
            Link="https://gerichtrestaurant-stage.netlify.app/" 
          />
          <Project 
            imgUrl={hoobank} 
            title="HooBank" 
            desc="Responsive Next.js app template." 
            Link="https://hoobank-stage.netlify.app/" 
          />
        </div>
      </div>
    </div>
  )
}

export default Projects

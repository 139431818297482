import React, { useState, useEffect } from 'react';
import './cursor.css'; // Make sure this path is correct for your CSS file

const CursorFollower = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: -100, y: -100 });
  const [followerPosition, setFollowerPosition] = useState({ x: -100, y: -100 });

  // Linear interpolation function
  const lerp = (start, end, amt) => {
    return (1 - amt) * start + amt * end;
  };

  useEffect(() => {
    // Update the cursor position state
    const moveCursor = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', moveCursor);

    // Animation frame function
    const animate = () => {
      const x = lerp(followerPosition.x, cursorPosition.x, 0.15);
      const y = lerp(followerPosition.y, cursorPosition.y, 0.15);

      setFollowerPosition({ x, y });
      requestAnimationFrame(animate);
    };

    // Start the animation
    requestAnimationFrame(animate);

    // Clean up
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, [followerPosition, cursorPosition]); // Depend on both positions

  return (
    <div
      className="cursor-follower"
      style={{
        left: `${followerPosition.x}px`,
        top: `${followerPosition.y}px`,
      }}
    />
  );
};

export default CursorFollower;

import React from 'react';
import './project.css';

const Project = ({ imgUrl, title, desc, Link }) => {
  return (
    <div className='portfolio__project-container'>
      <img src={imgUrl} alt='project' />
      <div className="portfolio__project-container_overlay">
          <h3>{title}</h3>
          <p>{desc}</p>
          <a href={Link} target="_blank" rel="noreferrer">Visit Site</a>
      </div>
    </div>
  )
}

export default Project
